import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/globals.css';
import '../styles/scss/main.scss';
import { ToastProvider } from 'react-toast-notifications';
import NextNProgress from "nextjs-progressbar";
import { useRouter } from "next/router";
import { DefaultSeo } from "next-seo";
import { Analytics } from '@vercel/analytics/react';


function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const canonicalUrl = (`https://mendelgen.com` + (router.asPath === "/" ? "": router.asPath.toLowerCase())).split("?")[0];
  //const canonicalUrl = (`http://localhost:3000` + (router.asPath === "/" ? "": router.asPath.toLowerCase())).split("?")[0];


  return (
    <>
      <DefaultSeo
        canonical={canonicalUrl}
      />
      <ToastProvider>
        <NextNProgress
        color="#7100FF"/>
          <Component {...pageProps} />
          <Analytics />
      </ToastProvider>
    </>
  );
}

export default MyApp;
